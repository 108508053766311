import { IfThenFieldInView, IfThenLineMode } from '../../contracts/if-then';
import { ApplicationMenu, ApplicationMenuType } from '../types';
import { ConcreteIndicatorViewModel } from '@/contracts/dictionary-view-model';
import { TopbarClockTimeFormat } from '@/types';
import { Instant } from '@js-joda/core';
import { OptionsObject, SnackbarMessage } from 'notistack';
import { IfThenLineSettings } from 'src/if-then/model';
import { AppAction } from 'src/store/actions';

export interface UIState {
    superuserAccessEnabledUntil?: Instant;
    indicators: ConcreteIndicatorViewModel[];
    defaultLineSettings: IfThenLineSettings;
    snackbarMessage?: {
        message: SnackbarMessage;
        options?: OptionsObject;
    };
    menus: {
        application: ApplicationMenuType;
    };
    timeFormat: TopbarClockTimeFormat;
}

export const initialState: UIState = {
    superuserAccessEnabledUntil: undefined,
    indicators: [],
    defaultLineSettings: {
        lineMode: IfThenLineMode.formula,
        fieldInView: IfThenFieldInView.nlp,
    },
    snackbarMessage: undefined,
    menus: {
        application: ApplicationMenu.loggedOutClosed,
    },
    timeFormat: 'H:mm:ss',
};

export const ui = (state = initialState, action: AppAction): UIState => {
    switch (action.type) {
        case 'superuser::set-expiration': {
            return { ...state, superuserAccessEnabledUntil: action.superuserAccessEnabledUntil };
        }

        case 'indicators::set': {
            return { ...state, indicators: action.indicators };
        }

        case 'indicators::add': {
            return {
                ...state,
                indicators: [...state.indicators, action.indicator],
            };
        }

        case 'indicators::update': {
            return {
                ...state,
                indicators: state.indicators.map((x) => (action.indicator.id === x.id ? action.indicator : x)),
            };
        }

        case 'indicators::remove': {
            return {
                ...state,
                indicators: state.indicators.filter((x) => x.id !== action.indicator.id),
            };
        }

        case 'default-line-settings::set': {
            return { ...state, defaultLineSettings: action.defaultLineSettings };
        }

        case 'snackbar::set': {
            return {
                ...state,
                snackbarMessage: {
                    message: action.message,
                    options: action.options,
                },
            };
        }

        case 'snackbar::clear': {
            return { ...state, snackbarMessage: undefined };
        }

        case 'menu::set-application-menu':
            return {
                ...state,
                menus: {
                    ...state.menus,
                    application: action.menu,
                },
            };

        case 'timeFormat::set': {
            return { ...state, timeFormat: action.timeFormat };
        }

        default:
            return state;
    }
};
