import { AxiosError, AxiosResponse } from 'axios';
import { MonoTypeOperatorFunction, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface CustomError extends AxiosError {
    requestId?: string;
}

/**
 * Class which accesses standard @type { error: string; stack?: string }
 * payload which may exist on an error HTTP response sent by the API.
 */
export default class APIError extends Error {
    requestId: string | undefined;
    static isServerError(x: unknown): x is { error: string; stack?: string; requestId?: string } {
        return !!x && typeof (x as any).error === 'string';
    }

    /**
     * With stack returns a canonical Error object that browsers are able to print
     * in a verbose manner.
     */
    withStack = (): Error => {
        const e = new Error(this.message);
        e.name = this.name;
        e.stack = this.stack;
        return e;
    };

    constructor(error: CustomError) {
        super(error.message);

        this.name = `API Error (${error.response?.status || error.request?.status || error.code || 'Unspecified'})`;
        this.status = error.response?.status || error.request?.status || error.code || 'Unspecified';

        if (error.response && APIError.isServerError(error.response.data)) {
            this.message = error.response.data.error;
            this.stack = error.response.data.stack;
            this.transportError = {
                name: error.name,
                message: error.message,
                stack: error.stack,
            };
            this.requestId = error.response.data.requestId;
        } else {
            this.message = error.message;
            this.stack = error.stack;
        }
    }

    status: number | string;
    transportError?: Error;
}

/**
 * Rx operator wrapping errors thrown by the underlying API call in @class APIError
 * if they report to be coming from Axios codebase.
 */
export const mapAPIError =
    <T extends AxiosResponse<unknown>>(): MonoTypeOperatorFunction<T> =>
    (source$: Observable<T>) =>
        source$.pipe(catchError((error: AxiosError) => throwError(error.isAxiosError ? new APIError(error) : error)));
