import { ConcreteColumnTemplate } from '../../../../web/src/contracts/column-template';
import { UniverseRecord } from '../../../../web/src/contracts/universe';
import { AppAccessStatus, DefaultUniverseList, UserDetails } from '../../types';
import { QueryAction } from './queries';

export type LogoutAction = { type: 'logout' };
export const logout = (): LogoutAction => ({ type: 'logout' });

export type SetAccessTokenAction = { type: 'access-token::set'; token?: string };
export const setAccessToken = (token?: string): SetAccessTokenAction => ({
    type: 'access-token::set',
    token,
});

export type ClearAccessTokenAction = { type: 'access-token::clear'; reason?: string };
export const clearAccessToken = (reason?: string): ClearAccessTokenAction => ({
    type: 'access-token::clear',
    reason,
});

export type SetAppAccessStatus = { type: 'app-access-status::set'; appStatus: AppAccessStatus };
export const setAppAccessStatus = (appStatus: AppAccessStatus): SetAppAccessStatus => ({
    type: 'app-access-status::set',
    appStatus,
});

export type BeginBootstrapAction = { type: 'bootstrap::begin' };
export const beginBootstrap = (): BeginBootstrapAction => ({ type: 'bootstrap::begin' });

export type CompleteBootstrapAction = { type: 'bootstrap::complete' };
export const completeBootstrap = (): CompleteBootstrapAction => ({ type: 'bootstrap::complete' });

export type SetUserDetailsAction = { type: 'user-details::set'; userDetails?: UserDetails };
export const setUserDetails = (userDetails?: UserDetails): SetUserDetailsAction => ({
    type: 'user-details::set',
    userDetails,
});

export type SetupUniversesActions = {
    type: 'universes::setup';
    visibleUniverses: UniverseRecord[];
    defaultUniverse: UniverseRecord | null;
};

export const setupUniverses = (
    visibleUniverses: UniverseRecord[],
    defaultUniverse: UniverseRecord | null,
): SetupUniversesActions => ({
    type: 'universes::setup',
    visibleUniverses,
    defaultUniverse,
});

export type SetDefaultUniverseListAction = {
    type: 'default-universe-list::set';
    defaultUniverseList: DefaultUniverseList;
};

export const setDefaultUniverseList = (defaultUniverseList: DefaultUniverseList): SetDefaultUniverseListAction => ({
    type: 'default-universe-list::set',
    defaultUniverseList,
});

export type SetupDefaultColumnTemplate = {
    type: 'colTemplates::setup';
    defaultColumnTemplate: ConcreteColumnTemplate | null;
};

export const setupColumnTemplate = (
    defaultColumnTemplate: ConcreteColumnTemplate | null,
): SetupDefaultColumnTemplate => ({
    type: 'colTemplates::setup',
    defaultColumnTemplate,
});

export type AppAction =
    | QueryAction
    | SetAccessTokenAction
    | ClearAccessTokenAction
    | SetAppAccessStatus
    | BeginBootstrapAction
    | CompleteBootstrapAction
    | SetUserDetailsAction
    | LogoutAction
    | SetupUniversesActions
    | SetDefaultUniverseListAction
    | SetupDefaultColumnTemplate;
