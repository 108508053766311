import { getSystemOffset } from './time';
import { DateTimeFormatter, Instant, LocalDateTime, OffsetDateTime } from '@js-joda/core';

export type Serialized<T> = {
    [P in keyof T]: T[P] extends Instant
        ? string
        : T[P] extends Array<infer U>
        ? Array<Serialized<U>>
        : T[P] extends object
        ? Serialized<T[P]>
        : T[P];
};

export function replacer(key: string, value: any): any {
    if (value instanceof Instant) {
        return value.toString();
    }
    return value;
}

const ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;
export function reviver(_key: string, value: any): any {
    if (typeof value === 'string') {
        if (value.match(ISO_8601_FULL)) {
            const hasTimeZone = value.indexOf('Z') >= 0;

            return hasTimeZone
                ? OffsetDateTime.parse(value, DateTimeFormatter.ISO_OFFSET_DATE_TIME).toInstant()
                : LocalDateTime.parse(value).toInstant(getSystemOffset());
        }
    }
    return value;
}

export function serializeObject<T extends {}>(input: T): Serialized<T> {
    const serialized: any = {};
    for (const [key, value] of Object.entries(input)) {
        serialized[key] = replacer(key, value);
    }
    return serialized;
}

export function reviveObject<T>(input: Serialized<T>): T {
    const revived: any = {};
    for (const [key, value] of Object.entries(input)) {
        revived[key] = reviver(key, value);
    }
    return revived;
}
