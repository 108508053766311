import { useEffect, useRef } from 'react';

type Subscription = { unsubscribe: () => void };

export function useSubscription(subscribe: () => Subscription | undefined, deps: any[] = []) {
    const subRef = useRef<Subscription>();
    useEffect(() => {
        subRef.current = subscribe?.();

        return () => {
            subRef.current?.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
