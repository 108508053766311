import spinner from './loading.svg';
import styles from './spinner.module.scss';
import classnames from 'classnames';
import React, { useRef } from 'react';

export interface SpinnerProps {
    size?: 'small' | 'medium' | 'large' | 'flexible';
    hidden?: boolean;
    className?: string | undefined;
    centered?: boolean;
}

type SizeClassNames = keyof Pick<
    typeof styles,
    'custom-spinner-large' | 'custom-spinner-medium' | 'custom-spinner-small' | 'customer-spinner-flexible'
>;

export const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({
    size = 'medium',
    hidden,
    className,
    centered = false,
}) => {
    const container = useRef<HTMLDivElement>(null);

    const sizeClassName = `custom-spinner-${size}` as SizeClassNames;

    return (
        <div className={classnames(styles['custom-spinner'], className)} hidden={hidden} data-testid="wait-spinner">
            <div
                className={classnames(styles[sizeClassName], centered ? styles['custom-spinner-centered'] : ``)}
                ref={container}
            >
                <object type="image/svg+xml" data={spinner}>
                    <img src={spinner} />
                </object>
            </div>
        </div>
    );
};

export const SpinnerOverlay: React.FC<React.PropsWithChildren<SpinnerProps>> = (spinnerProps) => {
    return (
        <div className={styles['custom-spinner-overlay']}>
            <Spinner centered {...spinnerProps} />
        </div>
    );
};
