import { DependencyList, useEffect, useMemo } from 'react';
import { BehaviorSubject, Observable } from 'rxjs';

export function useObservable<T>(
    observed: T,
    deps?: DependencyList,
): Observable<T> & Pick<BehaviorSubject<T>, 'value'> {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const subject = useMemo(() => new BehaviorSubject(observed), []);
    useEffect(() => {
        return () => subject.complete();
    }, [subject]);

    useEffect(
        () => {
            subject.next(observed);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps ? deps : [observed],
    );

    return subject;
}
