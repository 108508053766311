import { UserDetails } from '../types';
import { Instant } from '@js-joda/core';
import { AppAction } from 'src/store/actions';

export interface TokenState {
    token: string;
    expires?: Instant;
}

export interface AuthState {
    /** null = none; undefined = uninitialized */
    refreshToken?: TokenState | null | undefined;
    /** null = none; undefined = uninitialized */
    accessToken?: TokenState | null | undefined;
    /** null = none; undefined = uninitialized */
    masqAccessToken?: TokenState | null | undefined;

    masqUser?: UserDetails;
    user?: UserDetails;
}

export const initialState: AuthState = {};

export const auth = (state = initialState, action: AppAction): AuthState => {
    switch (action.type) {
        case 'access-token::set':
            return { ...state, accessToken: action.token };
        case 'access-token::clear':
            return { ...state, accessToken: null };
        // ToDo: better handling of auth state
        case 'refresh-token::set':
            return { ...state, refreshToken: action.token };

        case 'refresh-token::clear':
            return { ...state, refreshToken: null };
        case 'masq::access-token::set':
            return { ...state, masqAccessToken: action.token };
        case 'masq::access-token::clear':
            return { ...state, masqAccessToken: null };

        case 'user-details::set':
            return { ...state, user: action.userDetails };
        case 'masq::user-details::set':
            return { ...state, masqUser: action.userDetails };

        default:
            return state;
    }
};
