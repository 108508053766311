import { IndicatorImportViewModel } from '@/contracts/dictionary-view-model';
import { Strategy } from 'src/contracts/strategy';
import {
    AccountsWidgetModel,
    AggregatedPositionsWidgetModel,
    ChannelData,
    ExecutionsWidgetModel,
    GraphWidgetModel,
    IfThenBuilderWidgetModel,
    Layout,
    LocatesWidgetModel,
    MessagesWidgetModel,
    OrderBlotterWidgetModel,
    OrderEntryWidgetModel,
    OrdersWidgetModel,
    PositionsWidgetModel,
    StrategyWidgetModel,
    TableWidgetModel,
    WidgetContainer,
    WidgetTab,
    Workspace,
} from 'src/contracts/workspace';

export * from './sagaContext';

export type FeatureFlagSet = {
    disableSessionBarrier?: boolean;
    modernLocates?: boolean;
    envOverride?: string;
    keyboardCommands?: boolean;
};

export type FeatureFlag = keyof FeatureFlagSet;

export interface UserDetails {
    id: string;
    profile: { firstName: string; lastName: string };
    username: string;
    email: string;
    isActive: boolean;
    /**
     * @deprecated
     */
    isAdmin: boolean;
    /**
     * isSuperuser is a flag for global, unrestricted superuser permissions.
     */
    isSuperuser: boolean;
    isDeveloper: boolean;
}

export const makeContainerKey = (workspaceId: string, containerId: string): string =>
    `CONTAINER::${workspaceId}__${containerId}`;
export const makeChannelKey = (workspaceId: string, channelId: string): string =>
    `CHANNEL::${workspaceId}__${channelId}`;
export const makeTabKey = (workspaceId: string, containerId: string, tabId: string) =>
    `TABS::${workspaceId}__${containerId}__${tabId}`;
export const makeContainerLayoutKey = (workspaceId: string, containerId: string): string =>
    `CONTAINER_LAYOUT::${workspaceId}__${containerId}`;

export interface WorkspaceViewModel extends Omit<Workspace, 'containers' | 'channels'> {
    containerKeys: string[];
    channelKeys: string[];
    activeContainerId?: string;
    isDirty: boolean;
    maxLayoutHeight: number;
}

export interface ChannelDataViewModel extends ChannelData {
    workspaceId: string;
    channelKey: string;
    strategies?: (Strategy<IndicatorImportViewModel> | string)[] | null;
}

export interface ContainerViewModel extends Omit<WidgetContainer, 'tabs' | 'layout'> {
    containerKey: string;
    workspaceId: string;
    tabKeys: string[];
    layoutKey: string;
}

export interface ContainerLayoutViewModel extends Layout {
    layoutKey: string;
    containerKey: string;
    containerId: string;
    workspaceId: string;
}

/**
 * WidgetViewModel is going to be the UI-relevant data for each widget type
 * There is a divergence between what is "needed" on the UI
 * and what is "needed" on the server, and often we need to map between them
 * For example, take the StrategyWidgetViewModel -- it's almost a StrategyWidgetModel.
 * The server really doesn't care about the full imports on the strategy,
 * but on the UI side we want all of the imports expanded so that we can
 * ascertain the data type of each validation column from the strategy
 */

export interface StrategyWidgetViewModel extends StrategyWidgetModel {
    strategies?: (Strategy<IndicatorImportViewModel> | string)[];
}

export interface IfThenBuilderWidgetViewModel extends IfThenBuilderWidgetModel {
    strategy: string | Strategy<IndicatorImportViewModel> | null;
}

export type WidgetViewModel =
    | AccountsWidgetModel
    | AggregatedPositionsWidgetModel
    | ExecutionsWidgetModel
    | GraphWidgetModel
    | IfThenBuilderWidgetViewModel
    | LocatesWidgetModel
    | MessagesWidgetModel
    | OrderBlotterWidgetModel
    | OrderEntryWidgetModel
    | OrdersWidgetModel
    | PositionsWidgetModel
    | StrategyWidgetViewModel
    | TableWidgetModel;

export interface WidgetTabViewModel extends WidgetTab {
    workspaceId: string;
    containerId: string;
    containerKey: string;
    channelDataKey: string | null;
    isActive: boolean;
    tabKey: string;
    widget: WidgetViewModel;
}

export const ApplicationMenu = {
    loggedOutOpen: 'logged-out::open',
    loggedOutClosed: 'logged-out::closed',
    loggedInClosed: 'logged-in::closed',
    dashboard: 'dashboard',
};

export type ApplicationMenuType = (typeof ApplicationMenu)[keyof typeof ApplicationMenu];
