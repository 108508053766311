import { Dictionary } from './objectDictionary';
import { ChronoUnit, Instant, IsoFields, TemporalUnit, ZoneOffset } from '@js-joda/core';
import { Anchor, BarLength } from '@thinkalpha/language-services';

export {
    isAbsolutePointInTime,
    isBarCountPointInTime,
    isPointInTime,
    isRelativePointInTime,
    isTimeframe,
} from '@thinkalpha/language-services';
export type {
    AbsolutePointInTime,
    Anchor,
    BarLength,
    CountPointInTime,
    PointInTime,
    RelativePointInTime,
    SlidingWindow,
    Timeframe,
} from '@thinkalpha/language-services';

type FullPeriodType = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';

export enum PeriodType {
    second = 's',
    minute = 'm',
    hour = 'h',
    day = 'd',
    week = 'W',
    month = 'Mo',
    quarter = 'Q',
    year = 'Y',
}

export enum BarTypes {
    _10s = 'BarRecord(_10s)',
    _30s = 'BarRecord(_30s)',
    _1m = 'BarRecord(_1m)',
    _5m = 'BarRecord(_5m)',
    _10m = 'BarRecord(_10m)',
    _30m = 'BarRecord(_30m)',
    _1h = 'BarRecord(_1h)',
    _1d = 'BarRecord(_1d)',
}

export function barTypeToBarLength(barType: BarTypes): BarLength {
    switch (barType) {
        case BarTypes._1d:
            return { value: 1, period: PeriodType.day };
        case BarTypes._1h:
            return { value: 1, period: PeriodType.hour };
        case BarTypes._30m:
            return { value: 30, period: PeriodType.minute };
        case BarTypes._10m:
            return { value: 10, period: PeriodType.minute };
        case BarTypes._5m:
            return { value: 5, period: PeriodType.minute };
        case BarTypes._1m:
            return { value: 1, period: PeriodType.minute };
        case BarTypes._30s:
            return { value: 30, period: PeriodType.second };
        case BarTypes._10s:
            return { value: 10, period: PeriodType.second };
        default:
            throw new Error('unsupported BarType ' + barType);
    }
}

export function barLengthToBarType(barLength: BarLength): BarTypes {
    switch (true) {
        case barLength.value === 1 && barLength.period === PeriodType.day:
            return BarTypes._1d;
        case barLength.value === 1 && barLength.period === PeriodType.hour:
            return BarTypes._1h;
        case barLength.value === 30 && barLength.period === PeriodType.minute:
            return BarTypes._30m;
        case barLength.value === 10 && barLength.period === PeriodType.minute:
            return BarTypes._10m;
        case barLength.value === 5 && barLength.period === PeriodType.minute:
            return BarTypes._5m;
        case barLength.value === 1 && barLength.period === PeriodType.minute:
            return BarTypes._1m;
        case barLength.value === 30 && barLength.period === PeriodType.second:
            return BarTypes._30s;
        case barLength.value === 10 && barLength.period === PeriodType.second:
            return BarTypes._10s;
        default:
            throw new Error('unsupported BarLength ' + barLength);
    }
}

const PeriodTypeDict: Dictionary<PeriodType> = PeriodType;
export const PeriodNames: readonly FullPeriodType[] = Object.keys(PeriodType) as FullPeriodType[];
Object.freeze(PeriodNames);
export const PeriodNameMap: ReadonlyMap<PeriodType, FullPeriodType> = new Map<PeriodType, FullPeriodType>(
    PeriodNames.map((name) => [PeriodTypeDict[name], name]),
);
Object.freeze(PeriodNameMap);
export const PeriodNameToTemporalUnitMap: ReadonlyMap<PeriodType, TemporalUnit> = new Map<PeriodType, TemporalUnit>([
    [PeriodType.day, ChronoUnit.DAYS],
    [PeriodType.hour, ChronoUnit.HOURS],
    [PeriodType.minute, ChronoUnit.MINUTES],
    [PeriodType.month, ChronoUnit.MONTHS],
    [PeriodType.quarter, IsoFields.QUARTER_YEARS],
    [PeriodType.second, ChronoUnit.SECONDS],
    [PeriodType.week, ChronoUnit.WEEKS],
    [PeriodType.year, ChronoUnit.YEARS],
]);
Object.freeze(PeriodNameToTemporalUnitMap);
export const PeriodTypes: readonly PeriodType[] = PeriodNames.map((x) => PeriodTypeDict[x] as PeriodType);
Object.freeze(PeriodTypes);

export const setAnchorOnInstant = (instant: Instant, anchor: Anchor) => {
    let zonedDateTime = instant.atZone(ZoneOffset.UTC);

    if (anchor.year) zonedDateTime = zonedDateTime.withYear(anchor.year);
    if (anchor.month) zonedDateTime = zonedDateTime.withMonth(anchor.month);
    if (anchor.day) zonedDateTime = zonedDateTime.withDayOfMonth(anchor.day);
    if (anchor.hour) zonedDateTime = zonedDateTime.withHour(anchor.hour);
    if (anchor.minute) zonedDateTime = zonedDateTime.withMinute(anchor.minute);
    if (anchor.second) zonedDateTime = zonedDateTime.withSecond(anchor.second);

    return Instant.from(zonedDateTime);
};

export enum HistoryType {
    realtime = 'realtime',
    lookback = 'lookback',
    static = 'static',
    dynamic = 'dynamic',
    count = 'count',
}
